.close__icon{
    margin: 1rem;

    *{
        outline: none !important;
    }
}
.code__of__conduct__content{
    min-height: 80vh;
    width: 90%;
    margin: 0 auto;
}