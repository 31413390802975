.hero__wrapper {
  padding-bottom: 4rem;

  @media only screen and (max-width: 720px) {
    padding-bottom: 2rem;
  }
  .row {
    padding: 5rem;
    @media only screen and (max-width: 992px) {
      flex-direction: column-reverse;
      padding: 0;
      padding-bottom: 4rem;
    }

    .hero__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 4rem;

      @media only screen and (max-width: 720px) {
        padding-left: 0;
      }

      .title__wrapper {
        position: relative;
        width: max-content;

        @media only screen and (max-width: 720px) {
          width: 100%;
          text-align: center;
        }
        h1 {
          font-size: 4rem;
          span {
            font-size: 2.5rem;
          }

          @media only screen and (max-width: 720px) {
            font-size: 2.5rem;

            span {
              font-size: 1.5rem;
            }
          }

          @media only screen and (max-width: 325px) {
            font-size: 1.75rem;

            span {
              font-size: 1.25rem;
            }
          }
        }

        // &::after {
        //   position: absolute;
        //   content: "Extended";
        //   background: var(--color-highlight);
        //   color: white;
        //   font-weight: 500;
        //   padding: 0.25rem 0.5rem;
        //   top: -1.25rem;
        //   right: 0px;
        //   box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.16);
        //   font-size: 1.25rem;

        //   @media only screen and (max-width: 720px) {
        //     bottom: -1.75rem;
        //     top: auto;
        //     left: 0;
        //     font-size: 1rem;
        //     right: 0;
        //     width: max-content;
        //     margin: 0 auto;
        //   }
        // }
      }

      .hero__description {
        font-size: 1.1rem;
        font-weight: 300;
        width: 90%;
        color: var(--color-text-secondary);
        margin-bottom: 0.75rem;

        @media only screen and (max-width: 720px) {
          margin: 0 auto;
          margin-top: 2rem;
          font-size: 1rem;
          width: 80%;
          text-align: center;
        }

        @media only screen and (max-width: 325px) {
          font-size: 0.9rem;
        }
      }

      .hero__schedule {
        font-size: 1.75rem;
        color: var(--color-primary);

        @media only screen and (max-width: 720px) {
          text-align: center;
        }

        @media only screen and (max-width: 325px) {
          font-size: 1.3rem;
        }
      }

      .actions__wrapper {
        display: flex;

        
        @media only screen and (max-width: 720px) {
          flex-direction: column-reverse;
          justify-content: center;
          align-items: center;
        }

        .hero__call__to__action {
          margin-top: 1rem;
          cursor: pointer;
          color: var(--color-base);
          background: var(--color-primary);
          padding: 0.5rem 2.5rem;
          width: max-content;
          font-size: 1.5rem;
          border: 1px solid transparent;
          box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.16);
          transition: all ease-in-out 250ms;
          border-radius: 5px;

          @media only screen and (max-width: 720px) {
            margin: 0 auto;
            margin-top: 1rem;
          }

          @media only screen and (max-width: 325px) {
            font-size: 1.25rem;
          }
          &:hover {
            color: var(--color-base);
            background: #003f75;
            border: 1px solid var(--color-primary);
          }
        }
      }

      .join__discord__btn {
        margin-top: 1rem;
        cursor: pointer;
        margin-left: .5rem;
        background: var(--color-base);
        color: #7084d7;
        padding: 0.5rem 1.75rem;
        width: max-content;
        font-size: 1.5rem;
        border: 1px solid #7084d7;
        box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.16);
        transition: all ease-in-out 250ms;
        border-radius: 5px;

        @media only screen and (max-width: 720px) {
          margin: 0 auto;
          margin-top: 1rem;
        font-size: 1rem;
        }

        @media only screen and (max-width: 325px) {
          font-size: 1.25rem;
        }
        &:hover {
          background: #7084d7;
          color: var(--color-base);
          border: 1px solid transparent;
        }
      }
    }

    .hero__image {
      img {
        max-width: 110%;
        display: block;

        @media only screen and (max-width: 1200px) {
          max-width: 100%;
          margin-left: 2rem;
        }

        @media only screen and (max-width: 1080px) {
          max-width: 100%;
          margin-left: 4rem;
        }
        @media only screen and (max-width: 992px) {
          max-width: 75%;
          padding: 2rem;
          margin: 0;
        }
        @media only screen and (max-width: 768px) {
          max-width: 100%;
          margin: 0 auto;
        }
      }
    }
  }
}
