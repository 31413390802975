.communities__wrapper {
  padding: 3rem 8rem;
  padding-bottom: 5rem;

  @media only screen and (max-width: 992px) {
    padding: 3rem 4rem;
    padding-bottom: 5rem;
  }

  @media only screen and (max-width: 720px) {
    padding: 3rem 1rem;
    padding-bottom: 5rem;
  }

  .communities__title {
    margin-bottom: 4rem;
    text-align: left;
    // padding-left: 8rem;

    .communities__header {
      font-size: 2.25rem;
      text-align: left;
      font-weight: 500;
    }

    .communities__subheader {
      text-align: left;
      font-size: 1.25rem;
      color: var(--color-text-secondary);
      font-weight: 400;
    }

    @media only screen and (max-width: 720px) {
      padding-left: 0rem;
      text-align: center;
      padding: 0rem;

      .communities__header {
        font-size: 2.25rem;
        text-align: center;
        font-weight: 500;
      }

      .communities__subheader {
        text-align: center;
        font-size: 1.25rem;
        color: var(--color-text-secondary);
        font-weight: 400;
      }
    }
  }

  .communities__logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    // justify-content: space-between;
  }
}
