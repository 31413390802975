.community__container {
  width: 148px;
  height: 148px;
  display: flex;
  flex-direction: column;
  transition: all ease-in-out 250ms;
  justify-content: center;

  a {
    text-decoration: none;
    color: black;
  }

  .community__image {
    margin: 0 auto;
    border-radius: 150px;
    text-align: center;
    overflow: hidden;

    img {
      transition: all ease-in-out 250ms;
      margin: 0 auto;
      max-width: 75%;
    }
  }

  .community__name {
    margin-top: 0.5rem;
    font-size: 1rem;
    text-align: center;
  }

  &:hover {
    .community__image {
      img {
        max-width: 80%;
      }
    }
    a {
      color: var(--color-primary);
    }
  }
}
#Chandigarh,
#Delhi,
#Chennai,
#Pune,
#Hyderabad,
#Bangalore {
  padding: 1.25rem;
}
#Kerala {
  padding: 1.25rem;
  width: 172px;
  margin-top: 1rem;
}
// #Mumbai {
//   filter: invert(100%) sepia(0%) saturate(150%) hue-rotate(286deg)
//     brightness(103%) contrast(103%);
// }
